.shopping-cart {
    &__stack {
        overflow-y: auto;
        max-height: 50vh;

        .hstack {
            padding: 5px;
        }
    }
}
