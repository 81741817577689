.back-drop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: .5;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: #535353;
}

.other-back-btn {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.offcanvas-main {
    width: 450px !important;
    z-index: 10000;
}

.offcanvas-full {
    width: 100vw !important;
    z-index: 10000;

    .offcanvas-body {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

