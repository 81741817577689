.family-tree-container {
    min-height: calc(100vh - 307px);
}

.downline_container {
    font-weight: bold;
}

.wrapper-unilevel-tree {
    width: 100%;
    min-height: 600px;
    overflow: auto;
}

.treeCustom {
    position: relative;
    width: max-content;
    overflow: auto;
}

@media (max-width: 926px) {
    .treeCustom {

        padding-right: 2em;
        position: relative !important;
        width: 100% !important;
    }

    .treeCustom li a {
        text-decoration: none;

    }
}

@media (min-width: 927px) {
    * {
        padding: 0
    }

    .treeCustom li.root2 {
        width: 100000px;
    }

    .treeCustom ul {
        padding-top: 20px;
        position: relative;
        transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s
    }

    .treeCustom li {
        padding-bottom: 5px;
        float: left;
        text-align: center;
        list-style-type: none;
        position: relative;
        padding: 20px 5px 0;

        transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s
    }

    .treeCustom li::after,
    .treeCustom li::before {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        border-top: 1px solid #ccc;
        width: 50%;
        height: 20px
    }

    .treeCustom li::after {
        right: auto;
        left: 50%;
        border-left: 1px solid #ccc
    }

    .treeCustom li:only-child::after,
    .treeCustom li:only-child::before {
        display: none
    }

    .treeCustom li:only-child {
        padding-top: 0
    }

    .treeCustom li:first-child::before,
    .treeCustom li:last-child::after {
        border: 0 none
    }

    .treeCustom li:last-child::before {
        border-right: 1px solid #ccc;
        border-radius: 0 5px 0 0;
        -webkit-border-radius: 0 5px 0 0;
        -moz-border-radius: 0 5px 0 0
    }

    .treeCustom li:first-child::after {
        border-radius: 5px 0 0;
        -webkit-border-radius: 5px 0 0;
        -moz-border-radius: 5px 0 0
    }

    .treeCustom ul ul::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        border-left: 1px solid #ccc;
        width: 0;
        height: 20px
    }

    .treeCustom li a {
        border: 1px solid #ccc;
        padding: 5px 10px;
        text-decoration: none;
        color: #666;
        font-family: arial, verdana, tahoma;
        font-size: 11px;
        display: inline-block;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s
    }

    .treeCustom li a:hover,
    .treeCustom li a:hover+ul li a {
        background: #c8e4f8;
        color: #000;
        border: 1px solid #94a0b4
    }

    .treeCustom li a:hover+ul li::after,
    .treeCustom li a:hover+ul li::before,
    .treeCustom li a:hover+ul ul::before,
    .treeCustom li a:hover+ul::before {
        border-color: #94a0b4
    }
}