.footer {
    position: static;
    // bottom: 0;
    z-index: 9000;
    // left: 0;
    // width: 100%;
    // height: 75px;
}

.footer-mobile {
    z-index: 9999;
    position: absolute;
    background-color: white;
    color: black;
    top: 25px !important;
    right: 25px;
    color: #000;
    padding: 10px;
    border-radius: .25rem;
}


@media screen and (max-width: 768px) {
    .footer {
        position: static;
    }

    .footer-mobile {
        top: 10px;
    }
}