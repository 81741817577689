.product-details-container {
    min-height: calc(100vh - 312px);
}

.product-details-page {
    &__main-img {
        height: 350px;
        object-fit: contain;
    }

    &__option-img {
        height: 200px;
        object-fit: contain;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .product-details-page {
        &__option-img {
            height: 400px;
            object-fit: contain;
            cursor: default;
        }
    }
}
